<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <g-card page-title="Newsletter Logs" page-icon="mdi-contacts">
    <g-list-page
        :headers="headers"
        :actions="actions"
        :columns="columns"
        :show-add-button="false"
        stateendpoint="newsletterLog.newsletterLogs"
        :descending="true"
        sort="id"
        @loadData="loadData"
    >
      <template v-slot:overlay>
        <page-overlay :overlay="isOverlay" />
      </template>
      <template v-slot:top>
        <v-row>
          <v-col cols="12" md="6" sm="8">
            <g-p-search
                hint="Minimum two characters needed to search. Columns(name)"
                @applySearch="handleSearch"
            />
          </v-col>
          <v-col cols="12" md="6" sm="4">
            <add-button
                text="Remove all logs"
                icon="mdi-bookmark-remove"
                :toolbar="true"
                @onButtonClick="removedLogs({}, false)"
                class="ma-2 justify-end"
            />
          </v-col>
        </v-row>
      </template>
    </g-list-page>
    <confirm-popup
        :show="showConfirm"
        confirm-event="onConfirmItem"
        @closePopupHandler="showConfirm = false"
        @onConfirmItem="confirmEvent(currentSelectedItem, true)"
    >
      <div class="pt-5 pb-3">
        <!-- eslint-disable-next-line  -->
        <span>{{ confirmTxt }}</span>
      </div>
    </confirm-popup>
  </g-card>
</template>
<script>
import ListMixins from "../../components/mixins/list/List";
import GCard from "../../components/GCard";
import GListPage from "../../components/list/GListPage";
import PageOverlay from "../../components/PageOverlay";
import GPSearch from "../../components/GPSearch";
import AddButton from "../../components/buttons/AddButton";
import ConfirmPopup from "../../components/popups/ConfirmPopup";

export default {
  name: 'NewsletterLogs',
  metaInfo: {
    title: 'shantoitfirm.com',
    titleTemplate: 'Admin Dashboard - Newsletter Logs | %s',
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'googlebot', content: 'noindex' }
    ]
  },
  components: {
    ConfirmPopup,
    AddButton,
    GPSearch,
    PageOverlay,
    GListPage,
    GCard,
  },
  mixins: [ListMixins],
  data() {
    return {
      headers: [
        {
          text: 'Logs',
          value: 'logs'
        },
        {
          text: 'Error At',
          align: 'right',
          value: 'created_at'
        },
      ],
      columns: [
        {
          name: "created_at",
          callback: "getDate"
        },
      ],
      actions: {
        load: "newsletterLog/load",
        clear: "newsletterLog/clear",
      }
    }
  }
}
</script>
